<template>
  <div id='voucher-information-component'>
    <div class='flex-table'>
      <div class='customer-row'>
          <div class='customer-text bold-text'>{{ this.customerText }}</div>
          <div class='customer-text bold-text'>{{ this.customerName }}</div>
          <div class='address-text bold-text'>{{ this.addressText }}</div>
          <div class='text'>{{ this.customerAddress }}</div>
      </div>
        <div class='customer-row'>
          <div class='bold-text'>
            {{ this.taxText }} {{ this.invoiceData.taxNumber }}
          </div>
          <div class='email'>
            <div class='bold-text'>
              {{ this.emailText }}
              <span class='text'>{{ this.customer.email }}</span>
            </div>
          </div>
          <div class='bold-text'>
            {{ this.phoneText }} {{ this.customer.phoneNumber }}
          </div>
        </div>
        <div class="voucher-row">
          <div class='bold-text'>{{ this.paymentMethodText }}</div>
          <div>{{ this.cardPaymentText }}</div>
        </div>
        <div class='voucher-row'>
          <div class='bold-text'>{{ this.dueDateText }}</div>
          <div>{{ formatDate(this.voucher.date) }}</div>
        </div>
        <div class='voucher-row'>
          <div class='bold-text'>{{ this.creationDateText }}</div>
          <div>{{ formatDate(this.voucher.createdAt) }}</div>
        </div>
        <div class='voucher-row'>
          <div class='bold-text'>{{ this.serialNumberText }}</div>
          <div>{{ this.voucher.voucherIdentifier }}</div>
        </div>
        <div class='comment-section'>
          <div class='bold-text'>{{ this.commentText }}</div>
        </div>
        <div class='identifier-section'>
          <div class='bold-text'>{{ this.identifierText }}</div>
          <div >{{ this.getIdentifierNumber(this.voucher.voucherIdentifier) }}</div>
        </div>
        <div class='order-identifier-section'>
          <div class='bold-text'>{{ this.orderIdentifierText }}</div>
          <div >{{ this.orderIdentifier }}</div>
        </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';

export default {
  name: '',
  props: {
    voucher: {
      type: Object,
    },
    customer: {
      type: Object,
    },
    invoiceData: {
      type: Object,
    },
    orderIdentifier: {
      type: String,
    },
  },
  components: {},
  data: () => ({
    customerText: 'Vevő / Customer:',
    addressText: 'Cím / address: ',
    taxText: 'Adószám / TAX: ',
    emailText: 'E-mail: ',
    phoneText: 'Telefon / phone: ',
    paymentMethodText: 'Fizetési mód / Payment method: ',
    cardPaymentText: 'Bankkártyás vásárlás',
    dueDateText: 'Érvényesség napja / due date: ',
    creationDateText: 'Voucher kelte / date: ',
    serialNumberText: 'Sorszám / NO: ',
    commentText: 'Megjegyzés: ',
    identifierText: 'Azonosító: ',
    orderIdentifierText: 'Rendelés azonosító: ',
  }),
  created() {},
  computed: {

    customerName() {
      if (this.customer.partner) {
        return this.customer.partner.par_nev;
      }
      return `${this.customer.firstName} ${this.customer.lastName}`;
    },
    customerAddress() {
      return `${this.invoiceData.zipCode} ${this.invoiceData.cityName}, ${this.invoiceData.publicSpaceName} ${this.invoiceData.publicSpaceType} ${this.invoiceData.houseNumber}`;
    },
  },
  methods: {
    pad(pad, str, padLeft) {
      if (typeof str === 'undefined') return pad;
      if (padLeft) {
        return (pad + str).slice(-pad.length);
        // eslint-disable-next-line no-else-return
      } else {
        return (str + pad).substring(0, pad.length);
      }
    },
    getIdentifierNumber(identifier) {
      const number = identifier.split('/')[1];
      return Number(number);
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD');
    },
  },
};
</script>
<style lang='scss' scoped>
#voucher-information-component {
  --bold-text-size: 18px;
  --text-size: 16px;

  .flex-table {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 50px;

    &:first-of-type {
        border-top: 2px solid black;
        border-left: 2px solid black;
    }
  }

  .customer-row {
    width: calc(100% / 2);
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    padding: 5px;
  }

  .voucher-row {
    width: calc(100% / 4);
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    padding: 5px;
  }

  .comment-section {
    width: calc(100% / 3);
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    padding: 5px;
  }

  .identifier-section {
    width: calc((100% / 3));
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    padding: 5px;
  }

  .order-identifier-section {
    width: calc((100% / 3));
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    padding: 5px;
  }

  .address-text {
    margin-top: 15px;
  }

  .email {
    display: flex;
    align-content: center;
    justify-content: flex-start;
  }

  .text {
    font-size: var(--text-size);
    transform: translateY(calc(var(--bold-text-size) - var(--text-size)));
  }

  .bold-text {
    font-size: var(--bold-text-size);
    font-weight: 700;
  }
}
</style>

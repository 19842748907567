<template>
  <div id="pdf-screen">
    <ticket-page v-for="(tickets, index) in getTickets(getOrder)" :key="index" :tickets='tickets' :headerText='ticketHeaderText' :footerText='ticketFooterText' :ticketInfoText='ticketInfoText' :isLastPage='index === getTickets(getOrder).length - 1' :pageNumber='index + 1' :maxPages='getMaxPages()' :orderIdentifier='orderIdentifier'/>
    <voucher-page v-for='(voucherTetelItem, index) in getVoucherTetelItems' :key='index' :partner="partner" :voucher='voucherTetelItem.voucherData' :voucherTetelItemList='new Array(voucherTetelItem)' :customer='customer' :invoiceData='getOrder.invoiceData' :headerText='voucherHeaderText' :footerText='voucherFooterText' :pageNumber='index + getTickets(getOrder).length + 1' :maxPages='getMaxPages()' :orderIdentifier='orderIdentifier'/>
  </div>
</template>

<script>
import TicketPage from '@/components/shared/elements/partials/TicketPage';
import VoucherPage from '@/components/shared/elements/partials/VoucherPage';
import pdfHelper from '../api/pdf';

export default {
  name: 'PdfScreen',
  props: {},
  components: {
    TicketPage,
    VoucherPage,
  },
  data: () => ({
    ticketHeaderText: '',
    ticketFooterText: '',
    ticketInfoText: '',
    voucherHeaderText: '',
    voucherFooterText: '',
    order: {
      orderItems: [],
      invoiceData: {},
    },
    customer: {},
    orderIdentifier: '',
    partner: undefined,
  }),
  created() {
    this.fetchData();
  },
  mounted() {},
  computed: {
    accessKey() {
      return this.$route.params.accessKey;
    },
    getOrder() {
      return this.order;
    },
    getOrderItems() {
      return this.getOrder.orderItems;
    },
    getVoucherTetelItems() {
      return this.getOrder.orderItems
        .filter((item) => item.voucherData)
        .map((item) => {
          const newItem = {
            ...item,
            amount: 1,
          };
          return newItem;
        });
    },
  },
  methods: {
    readDataStore: pdfHelper.readDataStore,
    getPdf: pdfHelper.getPdf,
    async getPdfFromBackend(path) {
      const result = await this.getPdf(path);
      const blob = new Blob([result.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);

      window.open(url);
    },
    async fetchData() {
      const result = await this.readDataStore(this.accessKey);
      this.ticketHeaderText = result.data.ticketHeaderText;
      this.ticketFooterText = result.data.ticketFooterText;
      this.ticketInfoText = result.data.ticketInfoText;
      this.orderIdentifier = result.data.order.orderIdentifier;
      this.voucherHeaderText = result.data.voucherHeaderText;
      this.voucherFooterText = result.data.voucherFooterText;
      this.order = result.data.order;
      this.customer = result.data.user;
      this.partner = result.partner;
    },
    getMaxPages() {
      return this.getTickets(this.getOrder).length + this.getVoucherTetelItems.length;
    },
    getTickets(order) {
      return this.chunk(this.filterTickets(order.orderItems), 3);
    },
    /* eslint-disable no-param-reassign */
    chunk(array, chunkSize) {
      return array.reduce((resultArray, currentValue, index) => {
        const chunkIndex = Math.floor(index / chunkSize);
        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [];
        }
        resultArray[chunkIndex].push(currentValue);
        return resultArray;
      }, []);
    },
    /* eslint-disable no-param-reassign */
    filterTickets(orderItems) {
      return orderItems.filter((item) => item.qrCodeId);
    },
  },
};
</script>

<style lang='scss' scoped>
#pdf-screen {

  background-size: 21cm 29.7cm;

  @media print {

    @page {
      size: A4 portrait;
      margin: 0;
    }

    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;

  }
}
</style>
